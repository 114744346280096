exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-robert-js": () => import("./../../../src/pages/team/robert.js" /* webpackChunkName: "component---src-pages-team-robert-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2014-04-15-internet-proxy-script-ubuntu-proxy-script-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2014-04-15-internet-proxy-script-ubuntu/proxy_script.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2014-04-15-internet-proxy-script-ubuntu-proxy-script-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-24-lorenz-attractor-lorenz-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2015-10-24-lorenz-attractor/lorenz.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-24-lorenz-attractor-lorenz-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-25-run-django-linux-apache-2-box-content-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2015-10-25-run-django-linux-apache2-box/content.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-25-run-django-linux-apache-2-box-content-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-31-master-background-tasks-celery-rabbitmq-and-supervisor-content-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2015-10-31-master-background-tasks-celery-rabbitmq-and-supervisor/content.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2015-10-31-master-background-tasks-celery-rabbitmq-and-supervisor-content-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2015-12-05-exploratory-mathematics-ipython-content-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2015-12-05-exploratory-mathematics-ipython/content.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2015-12-05-exploratory-mathematics-ipython-content-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2015-12-08-basic-statistics-and-timeseries-pandas-pandas-stats-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2015-12-08-basic-statistics-and-timeseries-pandas/pandas_stats.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2015-12-08-basic-statistics-and-timeseries-pandas-pandas-stats-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2016-05-26-compressed-sensing-python-compressed-sensing-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2016-05-26-compressed-sensing-python/compressed_sensing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2016-05-26-compressed-sensing-python-compressed-sensing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2016-07-25-dmd-python-dmd-python-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2016-07-25-dmd-python/dmd_python.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2016-07-25-dmd-python-dmd-python-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-01-optimal-svht-optimal-svht-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2016-08-01-optimal-svht/optimal_svht.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-01-optimal-svht-optimal-svht-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-03-spdmd-python-spdmd-python-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2016-08-03-spdmd-python/spdmd_python.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-03-spdmd-python-spdmd-python-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-05-mrdmd-python-mrdmd-python-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2016-08-05-mrdmd-python/mrdmd_python.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2016-08-05-mrdmd-python-mrdmd-python-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2018-04-12-kalman-example-kalman-example-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2018-04-12-kalman-example/kalman_example.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2018-04-12-kalman-example-kalman-example-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2019-04-19-opencv-in-python-virtual-env-opencv-in-python-virtual-env-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2019-04-19-opencv-in-python-virtual-env/opencv_in_python_virtual_env.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2019-04-19-opencv-in-python-virtual-env-opencv-in-python-virtual-env-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2019-04-23-opencv-keypoint-descriptors-opencv-keypoint-descriptors-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2019-04-23-opencv-keypoint-descriptors/opencv_keypoint_descriptors.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2019-04-23-opencv-keypoint-descriptors-opencv-keypoint-descriptors-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2023-03-01-tpl-curiosity-report-01-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2023-03-01-tpl-curiosity/report_01.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2023-03-01-tpl-curiosity-report-01-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2023-06-30-meep-double-slit-article-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2023-06-30-meep-double-slit/article.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2023-06-30-meep-double-slit-article-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-2023-07-10-python-transcriber-article-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/posts/2023-07-10-python-transcriber/article.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-2023-07-10-python-transcriber-article-mdx" */)
}

