import React from 'react'
import { Helmet } from 'react-helmet'

import defaultOgImage from '../../images/defaultOgImage.png'

// Default Open Graph Sharing Image
const DefaultOgImage = () => (
  <Helmet>
    <meta property='og:image' content={defaultOgImage} />
    <meta property='og:image:width' content={1200} />
    <meta property='og:image:height' content={630} />
    <meta name='twitter:card' content='summary_large_image' />
  </Helmet>
)

export default DefaultOgImage
