import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Container } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Helmet } from 'react-helmet'

import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import NotificationWrapper from '../components/common/NotificationWrapper'
import Notification from '../components/common/Notification'
import DefaultOgImage from '../components/common/DefaultOgImage'
import themeConfig from '../material-ui/theme'

const Layout = ({ children }) => {

  // The main landing page requires a slightly different layout than
  // the default layout found here. If any of the grid or container
  // configuration is changed here, ensure it is reflected in the
  // main page layout too.
  const landingPage = children?.props?.location?.pathname === '/'

  return (
    <NotificationWrapper duration={5000}>
      <Helmet>
        <title>Humatic Labs</title>

        {/* Google Analytics */}
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-FL5P5K22XS'></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-FL5P5K22XS');
          `}
        </script>
      </Helmet>

      <DefaultOgImage />

      <ThemeProvider theme={themeConfig}>
        <Grid
          container
          direction='column'
          sx={{ minHeight: '100vh' }}
        >
          <Notification/>

          { landingPage ? children : (
            <>
              {/* Header menu */}
              <Grid item sx={{ flexShrink: 0 }}>
                <Header />
              </Grid>
              {/* Main content */}
              <Grid item container sx={{ flexGrow: 1 }}>
                <Container maxWidth='lg' sx={{ my: 10 }}>
                  {children}
                </Container>
              </Grid>
            </>
          ) }

          {/* Footer content */}
          <Grid item sx={{ flexShrink: 0 }}>
            <Footer />
          </Grid>

        </Grid>
      </ThemeProvider>
    </NotificationWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout